



import wallet from "../../assets/image/dmca-badge-w200-5x1-06.png";
import gift from "../../assets/image/arrow.gif";
import React, { useState, useEffect } from 'react'
import axios from "axios";
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";


function Vipsattaking() {
  // const [user, setUsers] = useState(null);
  const [userr, setUserss] = useState(null);
  const [useadvertisement, setadvertisement] = useState(null);
  const [useadvertisement1, setadvertisement1] = useState(null);
  const [usefetchAdvertisements2, setfetchAdvertisements2] = useState(null);
  const [useallMarketData, setuseallMarketData] = useState(null);
  const [usefetchAdvertisements3, setfetchAdvertisements3] = useState(null);
  const navigate = useNavigate();



  const [time, setTime] = useState(null);
  console.warn(time)
 






  const fetchAdvertisements1 = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}public/api/fetchAdvertisements1`, { method: 'post' });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.warn(data); // Log the response data
      setadvertisement1(data);

    } catch (error) {
      console.error('Error:', error);
    }
  };





  const fetchAdvertisements2 = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}public/api/fetchAdvertisements2`, { method: 'post' });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.warn(data); // Log the response data
      setfetchAdvertisements2(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const getAllMarketData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}public/api/getAllMarketData`, { method: 'post' });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.warn(data); // Log the response data
      setuseallMarketData(data);
      // settime(data); 
    } catch (error) {
      console.error('Error:', error);
    }
  };





  const fetchAdvertisements3 = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}public/api/fetchAdvertisements3`, { method: 'post' });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.warn(data); // Log the response data
      setfetchAdvertisements3(data);
      // settime(data); 
    } catch (error) {
      console.error('Error:', error);
    }
  };







  








  useEffect(() => {
    loaduser();
    markets();
    results();
    fetchAdvertisements3();
    getAllMarketData();
    fetchAdvertisements2();
    fetchAdvertisements1();
    loaduser1();

  }, []);




  const [useresults, setresults] = useState(null);
  const [usesetmarket, setmarket] = useState(null);

  const results = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}public/api/market-year-results`, { method: 'post' });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.warn(data); // Log the response data
      setresults(data);

      const livetime = data.market_name; // Accessing livetime from the response data

      setmarket(livetime);
    } catch (error) {
      console.error('Error:', error);
    }
  };









  const markets = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}public/api/markets`, { method: 'POST' });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.warn(data); // Log the response data
      setadvertisement(data); // Update state with the response data
      // settime(response); 
      const livetime = data.livetime; // Accessing livetime from the response data
      setTime(livetime);

    } catch (error) {
      console.error('Error:', error);
    }
  };















  const [usemarketname, setusemarketname] = useState("")


  const loaduser = async () => {
    // const market_id = localStorage.getItem("market_id");
    // alert(market_id)
    // console.warn(market_id)

    // alert(user_id);
    let url = `${process.env.REACT_APP_API_URL}public/api/satta-chart-record2024`;
    // console.warn(url);
    // const formData = new FormData();
    // formData.append("market_id", market_id);
    // alert(dev_id)
    var config = {
      method: "POST",
      url: url,
    };
    axios
      .post(url, config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        setusemarketname(objectRes);
        console.warn(objectRes);
      })
      .finally(() => {
        // setTimeout(() => {
        // }, 1000);
      });
  };


  const handleClick1 = async (market_id) => {
    navigate('/Market');
    localStorage.setItem('marketchart_id', market_id,);

  };



  const [usemarketnamee, setusemarketnamee] = useState("")

  const loaduser1 = async () => {
    // const market_id = localStorage.getItem("market_id");
    // alert(market_id)
    // console.warn(market_id)

    // alert(user_id);
    let url = `${process.env.REACT_APP_API_URL}public/api/satta-chart-record2023`;
    // console.warn(url);
    // const formData = new FormData();
    // formData.append("market_id", market_id);
    // alert(dev_id)
    var config = {
      method: "POST",
      url: url,
    };
    axios
      .post(url, config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        setusemarketnamee(objectRes);
        console.warn(objectRes);
      })
      .finally(() => {
        // setTimeout(() => {
        // }, 1000);
      });
  };


  const handleClick2 = async (market_id) => {
    navigate('/Result');
    localStorage.setItem('marketchart_id', market_id,);

  };
  
















  return (
    <>
      {/* Google Tag Manager */}
      {/*  */}
      {/* End Google Tag Manager */}
      <link rel="canonical" href="Index2" />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <title>
      DPBOS |  DPBOS | Gali satta | Satta result  | Disawar
        satta
      </title>
      <meta
        name="Description"
        content=" DPBOS is a type of lottery game officially called Satta. We provide the fastest results for  
        DPBOS, DPBOS , Disawar Satta, and Satta Result Online."
      />
      <meta
        name="Keywords"
        content="DPBOS, Gali satta,  DPBOS, Satta result, sattaking, Satta, sattaresult, Satta matka, Matka result, Faridabad satta, 
        Gaziyabad satta, Disawar satta,  DPBOS up,  DPBOS result,  DPBOS online, Gali result, Desawar result,  DPBOS chart,  DPBOS live,
         Deshawar live result, Gali live result, Satta matka, Satta matka king, DPBOS 2022 chart,  DPBOS desawar,  DPBOS gali, Satta Number, Satta Game
         , Gali Number, Delhi DPBOS, Satta Bazar, Black v, Gali Single Jodi, Black Satta Result, Gali  DPBOS result, Disawar DPBOS result, Faridabad 
         DPBOS result,  DPBOS, Gaziyabad  DPBOS"
      />
      <link rel="stylesheet" href="boostrapp.css" />
      <link href="css/style8bcf8bcf.css?id=30" rel="stylesheet" type="text/css" />
      <link href="background3a9f3a9f.css?id=6" rel="stylesheet" type="text/css" />
      <link rel="icon" href="images/favicon.png" type="image/png" sizes="16x16" />
      <meta name="robots" content="index, follow" />
      <link rel="alternate" hrefLang="en" href="sattaking.html" />
      <meta name="viewport" content="width=device-width" />
      {/*  */}
      <meta
        name="google-site-verification"
        content="jelo6kLqlf-aQgsFEZVxmhbB4SA-v2PJXN0ZCTaOCYA"
      />

      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n        .tr1 {\n            background-color: #FFFFFF;\n            color: #000000;\n        }\n\n        p {\n            margin: 0 0 0px;\n        }\n\n        .border.row div.col-md-6.col-sm-6.gamefull:nth-of-type(7n + 1) {\n            background: #bf5010;\n            width: 100%;\n            min-height: auto;\n        }\n\n        .border.row div.col-md-6.col-sm-6:nth-of-type(7n + 1) a,\n        .border.row div.col-md-6.col-sm-6:nth-of-type(7n + 1) .gamefull-result {\n            color: #fff;\n        }\n\n        .border.row div.col-md-6.col-sm-6:nth-of-type(7n + 1) p {\n            color: yellow;\n        }\n\n        .pages {\n            height: 55px;\n            font-weight: 600;\n        }\n\n        .king {\n            width: 100%;\n         \n            text-align: center;\n            margin-top: 18px;\n            margin-bottom: 18px;\n        }\n\n        .king h1 {\n            font-size: 40px;\n            font-weight: bold;\n            color: #000;\n            padding: 6px;\n        }\n    "
        }}
      />


      <div>

      </div>


      <table className="pages">
        <tbody>
          <tr>
            <td width="14%">
              <a title="DPBOS" href="/">
                HOME
              </a>
            </td>
            <td width="24%">
              <a title=" DPBOS " href="sattamatkaking">
              DPBOS 
              </a>
            </td>
            <td width="24%">
              <a href="Sattakingrecordchartgali">SATTA CHART</a>
            </td>
            <td width="24%">
              <a href="Sattamatka">SATTA MATKA</a>
            </td>
            <td width="24%">
              <a href="Blacksattakingleaknumber">SATTA LEAK</a>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="king">
        <h1>  VIP  DPBOS </h1>
      </div>
      <div className="drag">
        <h2>  DPBOS BEST SITE SATTA RESULT</h2>
        <a href="https://www.dpbosmatka.in">DPBOSS</a>
      </div>
      <div align="center">
        <div className="style1">
          <p style={{ color: "black", fontSize: 24, fontWeight: "bold" }}>
            {/* 08 April 2024 03:58 PM */}
            {time}


          </p>
          <p className="style1-heading"> DPBOS Live Result Today</p>
          <p className="livegame"></p>
          <p className="liveresult" style={{ color: "balck", fontSize: "24px", fontWeight: "bold" }}></p>
          <div className="width_full_one">
            <div className="container-fluid">
              <div className="row ">


     {useadvertisement && useadvertisement.data && useadvertisement.data.livetodayResult && useadvertisement.data.livetodayResult.length > 0 && (
                  <React.Fragment>
                    {useadvertisement.data.livetodayResult.map((market, index) => (
                      <div className="col-md-12 p-0 col-sm-12 col-xs-12" >
                        <div key={index}>
                          <p className="livegame"> {market.market_name} </p>
                          <p className="liveresult"> {market.result} </p>


                        </div>

                      </div>
                    ))}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>








          <p />
        </div>

      </div>
      <div className="drag">
        <h2> DPBOS RECORD CHART</h2>


        <img style={{ margin: "auto" }} src={wallet} />
      </div>
      <div
        style={{
          color: "#000",
          fontWeight: "bold",
          fontStyle: "bold",
          fontSize: "large",
          textDecoration: "none",
          margin: 3,
          padding: 10,
          borderRadius: 10,
          textAlign: "center"
        }}
      >
        <p style={{ fontSize: 25, color: "#ffffff", paddingBottom: 8 }}>
          आज की लीक जोडी
        </p>
      
      </div>
      {/*  */}
      {/* <div class="gcse-search"></div> */}

      {useadvertisement1 && useadvertisement1.data && useadvertisement1.data.advertisement && useadvertisement1.data.advertisement.length > 0 && (

        <React.Fragment>
          {useadvertisement1.data.advertisement.map((market, index) => (
            <div
              style={{
                backgroundColor: "#fff",
                color: "#000",
                fontWeight: "bold",
                fontStyle: "bold",
                fontSize: "large",
                textDecoration: "none",
                borderWidth: 3,
                borderColor: "red",
                borderStyle: "outset",
                margin: 3,
                padding: 10,
                borderRadius: 10,
                textAlign: "center"
              }}




            >
              <div key={index}>

                <p style={{ fontSize: 16, color: "red" }}>👑</p>

                {/* Example of setting HTML content */}
                <div dangerouslySetInnerHTML={{ __html: market.description }}></div>
                <p style={{ fontSize: 16, color: "red" }}>👑</p>

                <p style={{ fontSize: 16, color: "blue" }} className="d-flex justify-content-center">👑{market.name}💣</p>

                <p style={{ fontSize: 16, color: "#000" }}>{market.post}👍</p>
                <p style={{ fontSize: 16, color: "#000" }}>{market.mobile}💵</p>
                <a href={`tel:${market.mobile}`}>
                  <button
                    style={{
                      height: 40,
                      width: "auto",
                      backgroundColor: "red",
                      color: "#FFF",
                      border: "solid 3px red",
                      borderRadius: 20
                    }}
                  >
                    <font size="4px">
                      <b>CALL NOW</b>
                    </font>
                  </button>
                </a>

                <a target="_blank" href={`https://wa.me/+${market.mobile}`}> <button
                    style={{
                      height: 40,
                      width: "auto",
                      backgroundColor: "#18a707",
                      color: "#FFF",
                      border: "solid 3px #18a707",
                      borderRadius: 20
                    }}
                  >
                    <font size="4px">
                      <b>WHATSAPP CHAT</b>
                    </font>
                  </button>
                </a>

              </div>
            </div>
          ))}
        </React.Fragment>

      )}





      <div
        style={{
          color: "#000",
          fontWeight: "bold",
          fontStyle: "bold",
          fontSize: "large",
          textDecoration: "none",
          margin: 3,
          padding: 10,
          borderRadius: 10,
          textAlign: "center"
        }}
      >
        {/* <p style={{ fontSize: 25, color: "#ffffff", paddingBottom: 8 }}>
          आज की लीक जोडी
        </p> */}
       
      </div>
      {/* Sattano.com */}
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-7108667654778024"
        data-ad-slot={6000997688}
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
      {/*
     */}
      <div className="bookmark">
        <font>
          Apne Phone Pe Sabse Pehele Result Pane K Lie Site ☆ BOOKMARK Karein
        </font>
      </div>



      {usefetchAdvertisements2 && usefetchAdvertisements2.data && usefetchAdvertisements2.data.advertisement && usefetchAdvertisements2.data.advertisement.length > 0 && (
        <React.Fragment>
          {usefetchAdvertisements2.data.advertisement.map((market, index) => (
            <div
              style={{
                backgroundColor: "#fff",
                color: "#000",
                fontWeight: "bold",
                fontStyle: "bold",
                fontSize: "large",
                textDecoration: "none",
                borderWidth: 3,
                borderColor: "blue",
                borderStyle: "outset",
                margin: 3,
                padding: 10,
                borderRadius: 10,
                textAlign: "center"
              }}
            >

              <div key={index}>
                <div>
                  {/* Example of setting HTML content */}
                  <div dangerouslySetInnerHTML={{ __html: market.description }}></div>
                  <p style={{ fontSize: 16, color: "#000" }}>{market.name}</p>
                  <p style={{ fontSize: 16, color: "red" }}>{market.post}</p>
                  <p style={{ fontSize: 16, color: "green" }}>{market.mobile}</p>
                  <a href={`tel:${market.mobile}`}>
                    <button
                      style={{
                        height: 40,
                        width: "auto",
                        backgroundColor: "red",
                        color: "#FFF",
                        border: "solid 3px red",
                        borderRadius: 20,
                      }}
                    >
                      <font size="4px">
                        <b>CALL NOW</b>
                      </font>
                    </button>
                  </a>
                  <a target="_blank" href={`https://wa.me/+${market.mobile}`}  style={{
                       
                        marginLeft:"10px"
                      }}
                    >
                    <button
                      style={{
                        height: 40,
                        width: "auto",
                        backgroundColor: "#18a707",
                        color: "#FFF",
                        border: "solid 3px #18a707",
                        borderRadius: 20,
                      }}
                    >
                      <font size="4px">
                        <b>WHATSAPP CHAT</b>
                      </font>
                    </button>
                  </a>

                </div>
              </div>
            </div>
          ))}
        </React.Fragment>
      )}








      {/* 
   <div class="gcse-search"></div> */}
      <div className="container-fluid">
        <div className="border row p-0">





        </div>
      </div>
      <table width="100%" border={1} align="center">
        <tbody>

          {usefetchAdvertisements3 && usefetchAdvertisements3.data && usefetchAdvertisements3.data.advertisement && usefetchAdvertisements3.data.advertisement.length > 0 && (

            <React.Fragment>

              {usefetchAdvertisements3.data.advertisement.map((market, index) => (
                <tr style={{ border: "2px solid blue" }}>
                  <td colSpan={2} bgcolor="#ffffff">
                    <div align="center" className="style1">
                      <div key={index}>
                        <Link to="/">
                          <font className="style5">SATTA GAME KA RECORD CHART</font>
                        </Link>
                        <Link to="/" className="d-block">
                          <font className="style6">UPGAMEKING-SATTA</font>
                        </Link>
                        {/* Example of setting HTML content */}
                        <p className="style7" dangerouslySetInnerHTML={{ __html: market.description }}>
                        </p>
                        <p className="style6">{market.name}</p>
                        <p className="style5">{market.post}</p>
                        <p className="style7"> <span className="d-block">Phone Number</span><span style={{ fontSize: 30 }}> {market.mobile}</span>
                        </p>
                        <a href={`tel:${market.mobile}`}>
                    <button
                      style={{
                        height: 40,
                        width: "auto",
                        backgroundColor: "red",
                        color: "#FFF",
                        border: "solid 3px red",
                        borderRadius: 20
                      }}
                    >
                      <font size="4px">
                        <b>CALL NOW</b>
                      </font>
                    </button>
                  </a>



                       
                        <font className="style7 d-block">Available on Whats App</font>

                      </div>
                    </div>
                  </td>
                </tr>
              ))}

            </React.Fragment>


          )}


        </tbody>
      </table>


      
    </>

  )
}

export default Vipsattaking