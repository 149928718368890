import React, { useState, useEffect } from "react";
import telegram from "../../assets/image/telegram.png";
import main_kalyan_apk from "../../assets/image/dpbos9oct.apk";
import Button from "react-bootstrap/Button";
import { FaDownload } from "react-icons/fa";

function Footer() {
  const [telegramLink, setTelegramLink] = useState("");
  const [whatsappLink, setWhatsappLink] = useState("");
  //  alert(telegramLink)

  const fetchAdvertisements1 = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}public/api/genral-setting`,
        { method: "POST" }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.warn(data); // Log the response data
      setWhatsappLink(data.data.whatsapp_link);
      setTelegramLink(data.data.telegram_link);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchAdvertisements1();
  }, []);

  return (
    <div>
      <a
        href={telegramLink}
        className="float telegram"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={telegram} alt="Telegram" />
      </a>

      <div>
        <div id="wrap">
          <a href={main_kalyan_apk} className="btn-slide2 float" target="_blank">
            <span className="circle2">
              <FaDownload />
            </span>
            <span className="title2">App Download</span>
            <span className="title-hover2">Click here</span>
          </a>
        </div>
        {/* <a
          href={main_kalyan_apk}
          className="float play_now d-flex"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button variant="primary" style={{ padding: "12px" }}>
            {" "}
            Download apk{" "}
          </Button>
        </a> */}

        <div id="footer_right" onClick={() => window.location.reload()}>
          <a href="#">Refresh</a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
